<template>
	<div class="row justify-around q-px-sm">
		<q-btn
			v-for="btn in btn_data"
			:key="btn.label"
			outline
			rounded
			stack
			class="col-sm-3 col-xs-12 q-ma-md"
			color="primary"
			size='md'
			:icon='btn.icon'
			:label="btn.label"
			@click="btn.click"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	name: "DashboardProfile",
	data() {
		return {
			btn_data: [
				{
					'label': 'Change Password',
					'icon': 'password',
					'click': this.changePassword,
				},
				{
					'label': 'Logout',
					'icon': 'logout',
					'click': this.logout,
				}
			],
		}
	},
	computed:{
		...mapGetters(['logged_user', 'current_CG'])
	},
	methods:{
		...mapActions(['logout', 'set_active_item']),
		changePassword: function(){
			this.$router.push({name: 'Change Password'})
		},
		goToProfile: function(){
			this.$router.push({name: 'Edit User', params: {id: this.logged_user.id}})
			this.set_active_item(5)
		},
		createBtnData(){
			let customer_group_settings = JSON.parse(this.current_CG.customer_group.settings);
			if (['ADMIN', 'DATA'].includes(this.logged_user.customer_permission) || customer_group_settings.send_verification_email) {
				this.btn_data.unshift({
					'label': 'Edit Profile',
					'icon': 'manage_accounts',
					'click': this.goToProfile,
				})
			}
		}
	},
	created(){
		this.createBtnData()
	}
}
</script>
